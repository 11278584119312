<template>
    <box class="p-3 pl-6 !rounded-md !shadow-md">
        <input-group slot="content">
            <div class="flex items-center" slot="label">
                <input-label v-text="label" />
            </div>
            <template slot="input">
                <div class="flex-1 mt-1">
                    <invisible-input :value="userName" :readonly="true" />
                </div>
            </template>
        </input-group>
    </box>
</template>

<script>
    import Box from '@/components/presentational/boxes/Box.vue';
    import InputGroup from '@/components/presentational/input/InputGroup.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';

    export default {
        components: {
            Box,
            InputGroup,
            InputLabel,
            InvisibleInput,
        },

        props: {
            label: {
                type: String,
                default: '',
            },
            userName: {
                type: String,
                default: '',
            },
        },
    };
</script>
