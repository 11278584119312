<template>
    <section class="flex flex-col gap-x-6 gap-y-12">
        <input-list :fields="identityFields" />

        <div class="grid grid-cols-2 gap-6">
            <div class="flex flex-col gap-6">
                <div class="flex items-center">
                    <icon class="mr-1.5" name="regular/house-chimney-2" />
                    <activix-label class="m-0" :label="$t('clientCard.contactType.home')" />
                </div>
                <input-list :fields="homeContactFields" :column-count="1" />
            </div>
            <div class="flex flex-col gap-6">
                <div class="flex items-center">
                    <icon class="mr-1.5" name="regular/baggage" />
                    <activix-label class="m-0" :label="$t('clientCard.contactType.work')" />
                </div>
                <input-list :fields="workContactFields" :column-count="1" />
            </div>
        </div>

        <div class="flex flex-col gap-6">
            <div class="flex items-center">
                <icon class="mr-1.5" name="regular/pin-1" />
                <activix-label class="m-0" :label="$t('clientCard.address')" />
            </div>
            <input-list :fields="addressFields" />
        </div>
    </section>
</template>

<script>
    import ActivixLabel from '@/components/elements/ActivixLabel';
    import CustomerEmailInput from '@/components/container/input/customer/CustomerEmailInput';
    import CustomerPhoneInput from '@/components/container/input/customer/CustomerPhoneInput';
    import InputList from '@/components/container/input/InputList';
    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import Customer from '@/entities/Customer';
    import Locale from '@/entities/Locale';

    export default {
        components: {
            ActivixLabel,
            InputList,
        },

        props: {
            customer: {
                type: Customer,
                required: true,
            },
        },

        computed: {
            addressFields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.address'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.address1,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t(`clientCard.province.${this.customer.country}`),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.province,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t(`clientCard.postalCode.${this.customer.country}`),
                        component: InvisibleInput,
                        props: {
                            mask: this.customer.country === 'CA' ? 'postalCode' : 'zipCode',
                            readonly: true,
                            value: this.customer.postal_code,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.city'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.city,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.country'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.country,
                        },
                    },
                ];
            },

            homeContactFields() {
                return [
                    {
                        label: this.$t('clientCard.phoneType.home'),
                        component: CustomerPhoneInput,
                        props: {
                            phone: this.customer.homePhone,
                        },
                    },
                    {
                        label: this.$t('clientCard.phoneType.cell'),
                        component: CustomerPhoneInput,
                        props: {
                            phone: this.customer.cellPhone,
                        },
                    },
                    {
                        label: this.$t('clientCard.emailType.home'),
                        component: CustomerEmailInput,
                        props: {
                            email: this.customer.homeEmail,
                        },
                    },
                ];
            },

            identityFields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.civility'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.civility,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.firstName'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.first_name,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.lastName'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.last_name,
                        },
                    },

                    {
                        column: 1,
                        label: `${this.$t('clientCard.civility')} (${this.$t('clientCard.secondContact')})`,
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.second_contact_civility,
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.secondContact'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.second_contact,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.language'),
                        component: InvisibleInput,
                        props: {
                            options: Locale.selectOptions('value', 'text'),
                            readonly: true,
                            value: this.customer.locale,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.sex'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.sex,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.birthDate'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.birth_date,
                        },
                    },
                    {
                        column: 2,
                        label: this.$t('clientCard.businessName'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: this.customer.business_name,
                        },
                    },
                ];
            },

            workContactFields() {
                return [
                    {
                        label: this.$t('clientCard.phoneType.work'),
                        component: CustomerPhoneInput,
                        props: {
                            phone: this.customer.workPhone,
                        },
                    },
                    {
                        label: this.$t('clientCard.emailType.work'),
                        component: CustomerEmailInput,
                        props: {
                            email: this.customer.workEmail,
                        },
                    },
                ];
            },
        },
    };
</script>
