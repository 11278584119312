<template>
    <general-box :pills="pills">
        <template #header>
            <customer-general-box-header :customer="contextCustomer" @update-customer="updateCustomer" />
        </template>

        <template #user-assignations>
            <customer-user-assignations
                class="p-3 border-b border-gray-200 bg-gray-100 | md:p-6 xl:p-3 2xl:p-6"
                :customer="contextCustomer"
            />
        </template>

        <template #tabs="{ activePill }">
            <customer-general-details
                :customer="contextCustomer"
                v-if="activePill === 'general'"
            />
            <customer-contact-details
                :customer="contextCustomer"
                v-else-if="activePill === 'contact'"
            />
        </template>
    </general-box>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';

    import CustomerContactDetails from '@/components/container/customer/CustomerContactDetails';
    import CustomerGeneralBoxHeader from '@/components/container/customer/CustomerGeneralBoxHeader';
    import CustomerGeneralDetails from '@/components/container/customer/CustomerGeneralDetails';
    import CustomerUserAssignations from '@/components/container/customer/CustomerUserAssignations';
    import GeneralBox from '@/components/container/boxes/GeneralBox';

    export default {
        components: {
            CustomerContactDetails,
            CustomerGeneralBoxHeader,
            CustomerGeneralDetails,
            CustomerUserAssignations,
            GeneralBox,
        },

        computed: {
            ...mapState(useContextStore, {
                contextCustomer: 'customer',
            }),

            pills() {
                return ['general', 'contact'].map(pill => ({
                    name: pill,
                    label: this.$t(`clientCard.boxes.general.tabs.${pill}`),
                }));
            },
        },

        methods: {
            ...mapActions(useContextStore, {
                updateContextCustomer: 'updateCustomer',
            }),

            async updateCustomer(data) {
                const updatedCustomer = await this.$api.customers.update(this.contextCustomer.id, data);

                this.updateContextCustomer(updatedCustomer);
            },
        },
    };
</script>
