<template>
    <input-list :fields="fields" />
</template>

<script>
    import { toCurrency } from '@/utils/numbers.js';

    import InputList from '@/components/container/input/InputList';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import Rating from '@/components/container/input/Rating';

    import ActivixDate from '@/value-objects/ActivixDate';
    import Customer from '@/entities/Customer.js';

    export default {
        components: {
            InputList,
        },

        props: {
            customer: {
                type: Customer,
                required: true,
            },
        },

        computed: {
            fields() {
                return [
                    {
                        column: 1,
                        label: this.$t('clientCard.boxes.general.lastVisitService'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: new ActivixDate(this.customer.last_presented_date).toHumanShort(true),
                        },
                    },
                    {
                        column: 1,
                        label: this.$t('clientCard.boxes.general.averageSpendingService'),
                        labelTooltip: this.$t('clientCard.services.averageSpendingTooltip'),
                        component: InvisibleInput,
                        props: {
                            readonly: true,
                            value: toCurrency(this.customer.average_spending || 0),
                        },
                    },
                    {
                        column: 2,
                        visible: this.customer.account.hasProcessField('rating'),
                        label: this.$t('clientCard.rating'),
                        component: Rating,
                        props: {
                            rating: this.customer.rating,
                            ratingComment: this.customer.rating_comment,
                            readonly: true,
                        },
                    },
                ];
            },
        },
    };
</script>
