<template>
    <vehicle-box
        :account="account"
        :pagination="pagination"
        :readonly="true"
        :type="type"
        :vehicles="currentPageVehicles"
        @change-page="changePage"
    >
        <template #filters>
            <vehicle-filters
                :account="account"
                :vehicles="allVehicles"
                ref="filters"
                @apply="onFiltersApply"
                @reset="onFiltersReset"
                @vehicles-filtered="onVehiclesFiltered"
            />
        </template>

        <template #item-content="{ vehicle }">
            <vehicle-list-item-content
                :account="account"
                :tabs-config="tabsConfig(vehicle)"
                :vehicle="vehicle"
            />
        </template>
    </vehicle-box>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store';

    import { sort, toMoney } from '@/utils';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox';
    import BudgetRangeInput from '@/components/container/input/vehicle/BudgetRangeInput';
    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleTextarea from '@/components/container/input/InvisibleTextarea';
    import LengthRangeInput from '@/components/container/input/vehicle/LengthRangeInput';
    import MakeWarrantyInput from '@/components/container/input/vehicle/MakeWarrantyInput';
    import ValueInput from '@/components/container/input/vehicle/ValueInput';
    import VehicleAppraisalWidget from '@/components/container/lead/vehicles/VehicleAppraisalWidget';
    import VehicleBox from '@/components/container/lead/boxes/VehicleBox';
    import VehicleFilters from '@/components/container/lead/vehicles/VehicleFilters';
    import VehicleListItemContent from '@/components/container/lead/vehicles/VehicleListItemContent';
    import VehiclePricingOverview from '@/components/container/lead/vehicles/VehiclePricingOverview';
    import YearRangeInput from '@/components/container/input/vehicle/YearRangeInput';

    import ActivixDate from '@/value-objects/ActivixDate';
    import ClientCardSelection from '@/entities/ClientCardSection';
    import LeadType from '@/entities/LeadType';
    import LeadVehicle from '@/entities/LeadVehicle';
    import LeadVehicleCategory from '@/entities/LeadVehicleCategory';
    import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
    import LeadVehicleFrequency from '@/entities/LeadVehicleFrequency';
    import LeadVehicleFuel from '@/entities/LeadVehicleFuel';
    import LeadVehicleIntention from '@/entities/LeadVehicleIntention';
    import LeadVehicleModality from '@/entities/LeadVehicleModality';
    import LeadVehicleRvCategory from '@/entities/LeadVehicleRvCategory';
    import LeadVehicleStockState from '@/entities/LeadVehicleStockState';
    import LeadVehicleTerm from '@/entities/LeadVehicleTerm';
    import LeadVehicleTradeType from '@/entities/LeadVehicleTradeType';
    import LeadVehicleTransmission from '@/entities/LeadVehicleTransmission';
    import LeadVehicleType from '@/entities/LeadVehicleType';

    export default {
        components: {
            VehicleBox,
            VehicleFilters,
            VehicleListItemContent,
        },

        props: {
            type: {
                required: true,
                validator(value) {
                    return LeadVehicleType.keys.includes(value);
                },
            },
        },

        data: () => ({
            filteredVehicles: [],
            hasFiltersApplied: false,
            pagination: {
                currentPage: 1,
                orderDirection: 'asc',
                perPage: 5,
                totalItemsCount: null,
            },
        }),

        computed: {
            ...mapState(useClientCardStore, ['leadBanks']),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextCustomer: 'customer',
            }),

            account() {
                return this.contextCustomer.account;
            },

            allVehicles() {
                return this.contextCustomer.vehicles
                    .reduce((vehicles, vehicle) => {
                        const { customer_vehicle_lead: customerVehicleLeads, ...customerVehicle } = vehicle;

                        customerVehicleLeads.forEach(vehicleLead => {
                            if (vehicleLead.type === this.type) {
                                vehicles.push({
                                    ...customerVehicle,
                                    ...vehicleLead,
                                });
                            }
                        });

                        return vehicles;
                    }, [])
                    .sort(this.orderByCreation)
                    .map(vehicle => new LeadVehicle(vehicle));
            },

            currentPageVehicles() {
                return this.filteredVehicles.slice(this.paginationOffset, this.paginationOffset + this.pagination.perPage);
            },

            customFieldsSection() {
                return this.type === LeadVehicleType.WANTED ? ClientCardSelection.VEHICLE_WANTED : ClientCardSelection.VEHICLE_EXCHANGE;
            },

            hasCustomFields() {
                return this.account.getSectionCustomFields(this.customFieldsSection).length > 0;
            },

            isPolestarAccount() {
                return this.account.isPolestar();
            },

            paginationOffset() {
                return (this.pagination.currentPage - 1) * this.pagination.perPage;
            },

            tradeReportEnabled() {
                return this.account.trade_report && (this.authUser.trade_report || this.authUser.isAdmin());
            },

            vehicleMechanicalCategories() {
                const categories = [
                    { value: 'mower', text: this.$t('clientCard.vehicles.mower') },
                    { value: 'blower', text: this.$t('clientCard.vehicles.blower') },
                    { value: 'water_pump', text: this.$t('clientCard.vehicles.water_pump') },
                    { value: 'outside', text: this.$t('clientCard.vehicles.outside') },
                    { value: 'generator', text: this.$t('clientCard.vehicles.generator') },
                    { value: 'border_size', text: this.$t('clientCard.vehicles.border_size') },
                    { value: 'motoculteur', text: this.$t('clientCard.vehicles.motoculteur') },
                ];

                return sort(categories, 'text');
            },

            vehicleTypeKey() {
                return `${this.type}_vehicles`;
            },
        },

        watch: {
            filteredVehicles() {
                this.pagination.totalItemsCount = this.filteredVehicles.length;
            },

            'pagination.orderDirection'() {
                this.$ls.set(`customer.vehicles.${this.type}.ordering`, this.pagination.orderDirection);
            },
        },

        methods: {
            ...mapActions(useContextStore, ['reloadCustomerVehicles']),

            async changePage({ newPage, newOrderDirection = null }) {
                this.$wait.start(`fetching.${this.vehicleTypeKey}`);

                await this.$forceNextTick();

                this.pagination.currentPage = newPage;

                if (newOrderDirection) {
                    this.pagination.orderDirection = newOrderDirection;
                }

                this.$wait.end(`fetching.${this.vehicleTypeKey}`);
            },

            async fetchVehicles() {
                if (
                    !this.contextCustomer.id ||
                    this.contextCustomer.vehicles.length ||
                    this.$wait.is('customer.initializing.vehicles')
                ) {
                    return;
                }

                this.$wait.start('customer.initializing.vehicles');

                await this.reloadCustomerVehicles(this.contextCustomer.id);

                this.$wait.end('customer.initializing.vehicles');
            },

            fieldIsVisible(field) {
                return this.account.hasVehicleField(field, this.type, false);
            },

            initOrderDirection() {
                const orderDirectionPreference = this.$ls.get(`customer.vehicles.${this.type}.ordering`);

                if (orderDirectionPreference) {
                    this.pagination.orderDirection = orderDirectionPreference;
                } else {
                    this.pagination.orderDirection = this.isPolestarAccount ? 'desc' : 'asc';
                }
            },

            onFiltersApply() {
                this.changePage({ newPage: 1 });
            },

            onFiltersReset() {
                this.changePage({ newPage: 1 });
            },

            onVehiclesFiltered({ filteredVehicles, hasFiltersApplied }) {
                this.filteredVehicles = filteredVehicles;
                this.hasFiltersApplied = hasFiltersApplied;
            },

            orderByCreation(vehicleA, vehicleB) {
                if (this.pagination.orderDirection === 'desc') {
                    return vehicleB.created_at.localeCompare(vehicleA.created_at);
                }

                return vehicleA.created_at.localeCompare(vehicleB.created_at);
            },

            showWarranty(vehicle) {
                return (
                    this.fieldIsVisible('warranty') &&
                    (vehicle.isExchange || vehicle.lead.isOfType(LeadType.RENEWAL))
                );
            },

            tradeTypeTooltip(vehicle) {
                return empty(vehicle.value)
                    ? this.$t('clientCard.vehicles.tradeTypeDisabledTooltip')
                    : this.$t('clientCard.vehicles.tradeTypeTooltip');
            },

            vehicleBank(vehicle) {
                if (vehicle.imported_bank && !vehicle.bank_id) {
                    return vehicle.imported_bank;
                }

                return this.leadBanks.find(bank => bank.id === vehicle.bank_id)?.name;
            },

            tabsConfig(vehicle) {
                const tabsConfig = {
                    info: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.info'),
                        fieldsConfig: [
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category'),
                                        label: this.$t('clientCard.vehicles.category'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleCategory.getTranslation(vehicle.category),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category_rv'),
                                        label: this.$t('clientCard.vehicles.categoryRV'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleRvCategory.getTranslation(vehicle.category_rv),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('category') && vehicle.category === LeadVehicleCategory.MECHANICAL,
                                        label: this.$t('clientCard.vehicles.mechanical'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: this.vehicleMechanicalCategories.find(option => option.value === vehicle.mechanical)?.text,
                                        },
                                    },
                                    {
                                        column: 1,
                                        label: this.$t('clientCard.vehicles.make'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.make,
                                        },
                                    },
                                    {
                                        column: 1,
                                        label: this.$t('clientCard.vehicles.model'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.model,
                                        },
                                    },
                                    {
                                        column: 1,
                                        label: this.$t('clientCard.vehicles.year'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.year,
                                        },
                                    },
                                    {
                                        column: 1,
                                        label: this.$t('clientCard.vehicles.version'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.trim,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('transmission'),
                                        label: this.$t('clientCard.vehicles.transmission'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleTransmission.getTranslation(vehicle.transmission),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && vehicle.lead.isService(),
                                        label: this.$t('accounts.edit.licensePlate'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.license_plate,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && vehicle.lead.isService(),
                                        label: this.$t('accounts.edit.recall'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.recall,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('engine'),
                                        label: this.$t('clientCard.vehicles.engine'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.engine,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('bodyType'),
                                        label: this.$t('clientCard.vehicles.bodyType'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.body_type,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('certified'),
                                        label: this.$t('clientCard.certified'),
                                        tooltip: this.$t('clientCard.certifiedTooltip'),
                                        component: ActivixCheckbox,
                                        props: {
                                            readonly: true,
                                            size: 'lg',
                                            value: vehicle.certified,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('vin'),
                                        label: this.$t('clientCard.vehicles.vin'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.vin,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('stock'),
                                        label: this.$t('clientCard.vehicles.stockState'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleStockState.getTranslation(vehicle.stock_state),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('stock'),
                                        label: this.$t('clientCard.vehicles.stock'),
                                        labelIconClassName: 'text-blue-500 text-sm',
                                        labelIconName: vehicle.lead.deposit ? 'bold/currency-dollar' : '',
                                        labelIconTooltip: this.$t('clientCard.vehicles.securityDeposit'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.stock,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('color_exterior'),
                                        label: this.$t('clientCard.vehicles.exteriorColor'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.color_exterior,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('color_interior'),
                                        label: this.$t('clientCard.vehicles.interiorColor'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.color_interior,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('mileage'),
                                        label: this.$t('clientCard.vehicles.mileage'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'km',
                                            readonly: true,
                                            value: vehicle.mileage,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('fuel'),
                                        label: this.$t('clientCard.vehicles.fuel'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleFuel.getTranslation(vehicle.fuel),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('drivingWheels'),
                                        label: this.$t('clientCard.vehicles.drivingWheels'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.driving_wheels,
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        visible: this.fieldIsVisible('year'),
                                        component: YearRangeInput,
                                        rawComponent: true,
                                        props: {
                                            readonly: true,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('budget'),
                                        component: BudgetRangeInput,
                                        rawComponent: true,
                                        props: {
                                            readonly: true,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('length'),
                                        component: LengthRangeInput,
                                        rawComponent: true,
                                        props: {
                                            readonly: true,
                                            vehicle,
                                        },
                                        events: {
                                            'update-vehicle': data => this.updateVehicleData(vehicle.id, data),
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('sleeping'),
                                        label: this.$t('clientCard.vehicles.sleeping'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.sleeping,
                                        },
                                    },
                                    {
                                        visible: this.fieldIsVisible('weight'),
                                        label: this.$t('clientCard.vehicles.weight'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.weight,
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('comment'),
                                        label: this.$t('accounts.edit.comment'),
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            readonly: true,
                                            value: vehicle.comment,
                                        },
                                    },
                                    {
                                        column: 2,
                                        label: this.$t('clientCard.vehicles.options'),
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            readonly: true,
                                            value: vehicle.option,
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    price: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.price'),
                        fieldsConfig: [
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('payment'),
                                        label: this.$t('clientCard.vehicles.payment'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.payment,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('payment_with_tax'),
                                        label: this.$t('clientCard.vehicles.paymentWithTax'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.payment_with_tax,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('frequency'),
                                        label: this.$t('clientCard.vehicles.frequency'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleFrequency.getTranslation(vehicle.frequency),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('term'),
                                        label: this.$t('clientCard.vehicles.term'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleTerm.getTranslation(vehicle.term),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('rate'),
                                        label: this.$t('clientCard.vehicles.rate'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'percent',
                                            readonly: true,
                                            value: vehicle.rate,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('initial_cash'),
                                        label: this.$t('clientCard.vehicles.initialCash'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.initial_cash,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.account.auto_renewal || this.fieldIsVisible('modality'),
                                        label: this.$t('clientCard.vehicles.modality'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleModality.getTranslation(vehicle.modality),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('residual'),
                                        label: this.$t('clientCard.vehicles.residual'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            mask: 'money',
                                            value: vehicle.residual,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('tire'),
                                        label: this.$t('clientCard.vehicles.tires'),
                                        component: ActivixCheckbox,
                                        props: {
                                            readonly: true,
                                            size: 'lg',
                                            value: vehicle.tire,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('offer_number'),
                                        label: this.$t('clientCard.vehicles.offer_number'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.offer,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('suffix'),
                                        label: this.$t('clientCard.vehicles.suffix'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.suffix,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isWanted && this.fieldIsVisible('order_number'),
                                        label: this.$t('clientCard.vehicles.order_number'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.order_number,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: vehicle.isWanted,
                                        component: VehiclePricingOverview,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            readonly: true,
                                            vehicle,
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    contract: {
                        label: this.$t('clientCard.boxes.vehicles.tabs.contract'),
                        fieldsConfig: [
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('balance'),
                                        label: this.$t('clientCard.vehicles.balance'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.balance,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('allowed_mileage'),
                                        label: this.$t('clientCard.vehicles.allowed_mileage'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'km',
                                            readonly: true,
                                            value: vehicle.allowed_mileage,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('institution'),
                                        label: this.$t('clientCard.institution'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: this.vehicleBank(vehicle),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('intention'),
                                        label: this.$t('clientCard.vehicles.intention'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleIntention.getTranslation(vehicle.intention),
                                        },
                                    },

                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('sold_by'),
                                        label: this.$t('accounts.edit.soldBy'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.sold_by,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: vehicle.isExchange && this.fieldIsVisible('sold_date'),
                                        label: this.$t('accounts.edit.soldDate'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.sold_date,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('security_deposit'),
                                        label: this.$t('clientCard.vehicles.securityDeposit'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.security_deposit,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('in_service_date'),
                                        label: this.$t('clientCard.vehicles.in_service_date'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: new ActivixDate(vehicle.in_service_date).toHumanShort(false),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.account.auto_renewal || this.fieldIsVisible('end_contract_date'),
                                        label: this.$t('clientCard.vehicles.contractEnd'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: new ActivixDate(vehicle.end_contract_date).toHumanShort(false),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.showWarranty(vehicle),
                                        label: this.$t('accounts.edit.warranty'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.warranty,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.showWarranty(vehicle),
                                        label: this.$t('accounts.edit.extendedWarranty'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: vehicle.extended_warranty,
                                        },
                                    },
                                    {
                                        column: 2,
                                        label: this.$t('clientCard.vehicles.warranty_type'),
                                        component: MakeWarrantyInput,
                                        props: {
                                            account: this.account,
                                            readonly: true,
                                            vehicle,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_warranty_date'),
                                        label: this.$t('accounts.edit.endWarrantyDate'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: new ActivixDate(vehicle.end_warranty_date).toHumanShort(false),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_warranty_mileage'),
                                        label: this.$t('accounts.edit.endWarrantyMileage'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'km',
                                            readonly: true,
                                            value: vehicle.end_warranty_mileage,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_extended_warranty_date'),
                                        label: this.$t('clientCard.boxes.vehicles.endExtendedWarrantyDate'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: new ActivixDate(vehicle.end_extended_warranty_date).toHumanShort(false),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('end_extended_warranty_mileage'),
                                        label: this.$t('clientCard.boxes.vehicles.endExtendedWarrantyMileage'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'km',
                                            readonly: true,
                                            value: vehicle.end_extended_warranty_mileage,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                };

                if (vehicle.isExchange) {
                    tabsConfig.appraisal = {
                        label: this.$t('clientCard.boxes.vehicles.tabs.appraisal'),
                        fieldsConfig: [
                            {
                                columnCount: 1,
                                fields: [
                                    {
                                        column: 1,
                                        visible: vehicle.lead.isService() || this.account.equity_alert,
                                        component: VehicleAppraisalWidget,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            readonly: true,
                                            vehicle,
                                        },
                                    },
                                ],
                            },
                            {
                                columnCount: 2,
                                fields: [
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('link'),
                                        label: this.$t('clientCard.vehicles.link'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.link,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('requested'),
                                        label: this.$t('clientCard.vehicles.requested'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.requested,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.fieldIsVisible('value'),
                                        component: ValueInput,
                                        rawComponent: true,
                                        props: {
                                            account: this.account,
                                            readonly: true,
                                            vehicle,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.actual_value'),
                                        labelTooltip: this.$t('clientCard.vehicles.actualValueTooltip'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.actual_value,
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.trade_type'),
                                        labelTooltip: this.tradeTypeTooltip(vehicle),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleTradeType.getTranslation(vehicle.trade_type),
                                        },
                                    },
                                    {
                                        column: 1,
                                        visible: this.tradeReportEnabled,
                                        label: this.$t('clientCard.vehicles.trade_notes'),
                                        component: InvisibleTextarea,
                                        fixedHeight: false,
                                        props: {
                                            disabled: true,
                                            value: vehicle.trade_notes,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('price'),
                                        label: this.$t('clientCard.vehicles.price'),
                                        component: InvisibleInput,
                                        props: {
                                            mask: 'money',
                                            readonly: true,
                                            value: vehicle.price,
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: vehicle.lead.isService(),
                                        label: this.$t('accounts.edit.purchaseDate'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: new ActivixDate(vehicle.purchase_date).toHumanShort(),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.fieldIsVisible('condition'),
                                        label: this.$t('clientCard.vehicles.condition'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: LeadVehicleCondition.getTranslation(vehicle.condition),
                                        },
                                    },
                                    {
                                        column: 2,
                                        visible: this.tradeReportEnabled && this.fieldIsVisible('accidented_damage_cost'),
                                        label: this.$t('clientCard.vehicles.accidentedTooltip'),
                                        component: InvisibleInput,
                                        props: {
                                            readonly: true,
                                            value: toMoney(vehicle.accidented_damage_cost, 2),
                                        },
                                    },
                                ],
                            },
                        ],
                    };
                }

                if (this.hasCustomFields) {
                    tabsConfig.custom = {
                        label: this.$t('clientCard.boxes.vehicles.tabs.custom'),
                    };
                }

                return tabsConfig;
            },
        },

        mounted() {
            this.initOrderDirection();
            this.fetchVehicles();
        },
    };
</script>
