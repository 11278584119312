<template>
    <header
        class="
            flex items-start h-12 ml-24 pl-3 space-x-6
            md:h-14 md:ml-32 md:pl-6
            xl:h-12 xl:ml-24 xl:pl-3
            2xl:h-14 2xl:ml-32 2xl:pl-3
        "
    >
        <div class="flex items-center gap-1.5 rounded-full text-sm bg-gray-400 px-3 py-0.5 2xl:py-1">
            <icon name="regular/graph-stats" />
            <span>Stat #1</span>
        </div>

        <div class="flex items-center gap-1.5 rounded-full text-sm bg-gray-400 px-3 py-0.5 2xl:py-1">
            <icon name="regular/graph-stats" />
            <span>Stat #2</span>
        </div>
    </header>
</template>

<script>
    export default {};
</script>
