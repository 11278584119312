<template>
    <header
        class="
            flex justify-between items-stretch border-b border-gray-200
            h-12 md:h-18 xl:h-12 2xl:h-18
        "
    >
        <div class="absolute -mt-16">
            <figure class="flex items-center justify-center w-30 h-30 rounded-full shadow-md bg-white cursor-default">
                <activix-avatar
                    class="flex-shrink-0 border border-gray-300 rounded-full text-gray-700 bg-gray-200"
                    :size="90"
                    :username="customer.fullName"
                />
            </figure>
        </div>

        <div class="flex-1 flex justify-between items-stretch ml-30">
            <div class="flex-shrink flex items-center px-3 space-x-3 | md:px-6 xl:px-3 2xl:px-6">
                <customer-primary-contact-type :customer="customer" />
            </div>

            <div class="flex-shrink-0 flex items-stretch border-l border-gray-200 divide-x divide-gray-200">
                <general-options @open-modal="openModal">
                    <template #modals>
                        <consent-config
                            name="consentConfigMoreInfo"
                            @closed="modals.consent.opened = false"
                            v-if="caslConsentLeadId"
                        />
                        <contact-preference
                            :communication-preference.sync="customer.communication_preference"
                            :has-niotext="customer.account.hasNioText()"
                            :opened="modals.preference.opened"
                            :unsubscribed-to-all-date="customer.unsubscribe_all_date"
                            :unsubscribed-to-email-date="customer.unsubscribe_email_date"
                            :unsubscribed-to-phone-date="customer.unsubscribe_call_date"
                            :unsubscribed-to-sms-date="customer.unsubscribe_sms_date"
                            @close="modals.preference.opened = false"
                            @update="updateCustomer"
                        />
                        <d-n-d
                            :opened="modals.dnd.opened"
                            :unsubscribed-to-all-date="customer.unsubscribe_all_date"
                            @close="modals.dnd.opened = false"
                            @update="updateCustomer"
                        />
                        <opt-out-management
                            :opened="modals.subscription.opened"
                            :unsubscribed-to-all-date="customer.unsubscribe_all_date"
                            :unsubscribed-to-email-date="customer.unsubscribe_email_date"
                            :unsubscribed-to-phone-date="customer.unsubscribe_call_date"
                            :unsubscribed-to-sms-date="customer.unsubscribe_sms_date"
                            @close="modals.subscription.opened = false"
                            @update="updateCustomer"
                        />
                    </template>
                </general-options>
            </div>
        </div>
    </header>
</template>

<script>
    import { mapState } from 'pinia';
    import { useAssociatedLeadsStore } from '@/store/modules/associatedLeads/store';

    import ConsentConfig from '@/components/modals/ConsentConfig';
    import ContactPreference from '@/components/modals/ContactPreference';
    import CustomerPrimaryContactType from '@/components/container/customer/CustomerPrimaryContactType';
    import DND from '@/components/modals/DND';
    import GeneralOptions from '@/components/container/lead/GeneralOptions';
    import OptOutManagement from '@/components/modals/OptOutManagement';

    import Customer from '@/entities/Customer';

    export default {
        components: {
            ConsentConfig,
            ContactPreference,
            CustomerPrimaryContactType,
            DND,
            GeneralOptions,
            OptOutManagement,
        },

        props: {
            customer: {
                type: Customer,
                required: true,
            },
        },

        data: () => ({
            modals: {
                consent: {
                    opened: false,
                },
                dnd: {
                    opened: false,
                },
                preference: {
                    opened: false,
                },
                subscription: {
                    opened: false,
                },
            },
        }),

        computed: {
            ...mapState(useAssociatedLeadsStore, ['associatedLeads']),

            caslConsentLeadId() {
                return this.associatedLeads[0]?.id;
            },
        },

        watch: {
            'modals.consent.opened'() {
                if (!this.modals.consent.opened || !this.caslConsentLeadId) {
                    return;
                }

                this.$modal.show('consentConfigMoreInfo', {
                    leadId: this.caslConsentLeadId,
                });
            },
        },

        methods: {
            openModal(modalName) {
                this.modals[modalName].opened = true;
            },

            updateCustomer(data) {
                this.$emit('update-customer', data);
            },
        },
    };
</script>
