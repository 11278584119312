<template>
    <div class="flex items-center gap-1.5 truncate | lg:text-lg xl:text-xl 2xl:text-2xl">
        <icon :class="contactIconClassName" :name="contactIconName" />

        <span
            class="font-semibold"
            v-text="contactName"
            v-if="isBusiness"
        />

        <span
            :class="{ 'font-semibold': index === contactNameParts.length - 1 }"
            :key="index"
            v-text="contactNamePart"
            v-for="(contactNamePart, index) in contactNameParts"
            v-else
        />
    </div>

</template>

<script>
    import Civility from '@/entities/Civility.js';
    import ContactType from '@/entities/ContactType.js';
    import Customer from '@/entities/Customer.js';

    export default {
        props: {
            customer: {
                type: Customer,
                required: true,
            },
        },

        computed: {
            businessContactName() {
                return this.customer.business_name || this.mainContactName;
            },

            civility() {
                if (!this.customer.civility) {
                    return '';
                }

                return Civility.getTranslation(this.customer.civility);
            },

            isBusiness() {
                return this.customer.primary_contact_type === ContactType.BUSINESS;
            },

            mainContactName() {
                return [this.customer.first_name, this.customer.last_name].join(' ').trim();
            },

            contactIconClassName() {
                if (this.customer.primary_contact_type === ContactType.MAIN) {
                    return 'text-md';
                }

                return '';
            },

            contactIconName() {
                if (this.customer.primary_contact_type === ContactType.MAIN) {
                    return 'regular/single-neutral-id-card-3';
                }

                if (this.customer.primary_contact_type === ContactType.BUSINESS) {
                    return 'regular/baggage';
                }

                return '';
            },

            contactName() {
                if (this.customer.primary_contact_type === ContactType.MAIN) {
                    return [this.civility, this.mainContactName].join(' ').trim();
                }

                if (this.customer.primary_contact_type === ContactType.BUSINESS) {
                    return this.businessContactName;
                }

                return '';
            },

            contactNameParts() {
                return this.contactName.split(' ');
            },
        },
    };
</script>
