<template>
    <div
        class="grid grid-cols-1 gap-3 | md:gap-6 xl:gap-3 2xl:gap-6"
        :class="showBdcAgent ? 'md:grid-cols-3' : 'md:grid-cols-2'"
    >
        <customer-user-assignation-item
            :label="$t('clientCard.advisor')"
            :user-name="customer.latest_user.fullName"
        />
        <customer-user-assignation-item
            :label="$t('clientCard.bdcAgent')"
            :user-name="customer.latest_bdc_user.fullName"
            v-if="showBdcAgent"
        />
        <customer-user-assignation-item
            :label="$t('clientCard.commercialShort')"
            :user-name="customer.latest_commercial.fullName"
        />
    </div>
</template>

<script>
    import CustomerUserAssignationItem from '@/components/container/customer/CustomerUserAssignationItem';

    import Customer from '@/entities/Customer';

    export default {
        components: {
            CustomerUserAssignationItem,
        },

        props: {
            customer: {
                type: Customer,
                required: true,
            },
        },

        computed: {
            showBdcAgent() {
                return this.customer.account.bdc_advisor;
            },
        },
    };
</script>
