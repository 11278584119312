<template>
    <div class="flex-1 flex items-center justify-center h-full" v-if="!isInitialized">
        <activix-spinner :message="$t('general.loading')" />
    </div>

    <div v-else>
        <portal to="left-sidebar">
            <associated-leads :customer="contextCustomer">
                <template #actions="{ isExpanded, relatedLeadId }">
                    <create-associated-lead
                        :account="contextCustomer.account"
                        :default-data="newAssociatedLeadDefaultData"
                        :related-lead-id="relatedLeadId"
                        :with-label="isExpanded"
                    />
                </template>
            </associated-leads>
        </portal>

        <customer-header />

        <div class="grid grid-cols-1 gap-6 items-stretch | xl:grid-cols-6">
            <customer-general-box class="col-span-full | xl:col-span-3" />
            <customer-notes-box class="col-span-full min-h-main-view | xl:col-span-3" />
            <customer-vehicle-box class="col-span-full | xl:col-span-3" type="wanted" />
            <customer-vehicle-box class="col-span-full | xl:col-span-3" type="exchange" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useAssociatedLeadsStore } from '@/store/modules/associatedLeads/store';
    import { useContextStore } from '@/store/modules/context/store';

    import AssociatedLeads from '@/components/container/lead/AssociatedLeads';
    import CreateAssociatedLead from '@/components/container/lead/CreateAssociatedLead';
    import CustomerGeneralBox from '@/components/container/customer/CustomerGeneralBox';
    import CustomerHeader from '@/components/container/customer/CustomerHeader';
    import CustomerNotesBox from '@/components/container/customer/boxes/CustomerNotesBox';
    import CustomerVehicleBox from '@/components/container/customer/boxes/CustomerVehicleBox';

    export default {
        components: {
            AssociatedLeads,
            CreateAssociatedLead,
            CustomerGeneralBox,
            CustomerHeader,
            CustomerNotesBox,
            CustomerVehicleBox,
        },

        metaInfo() {
            return {
                title: this.customerName,
            };
        },

        computed: {
            ...mapState(useAssociatedLeadsStore, ['associatedLeads']),
            ...mapState(useContextStore, {
                contextCustomer: 'customer',
            }),

            customerName() {
                let customerName = this.contextCustomer.fullName;

                if (!customerName) {
                    customerName = this.associatedLeads.find(lead => lead.fullName)?.fullName;
                }

                return customerName || '';
            },

            isInitialized() {
                return !!this.contextCustomer.id && !this.$wait.is('fetching.contextCustomer');
            },

            newAssociatedLeadDefaultData() {
                return {
                    account: this.contextCustomer.account_id,
                    advisor: this.contextCustomer.latest_user_id,
                    bdc: this.contextCustomer.latest_bdc_user_id,
                    civility: this.contextCustomer.civility,
                    email: this.contextCustomer.homeEmail,
                    name: this.contextCustomer.fullName,
                    phone: this.contextCustomer.homePhone,
                };
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler(newId, previousId) {
                    if (!newId || newId === previousId) {
                        return;
                    }

                    this.setContextCustomerAction(newId);
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextCustomerAction']),
        },
    };
</script>
