<template>
    <notes-box
        :account="contextCustomer.account"
        :filters="filters"
        :filter-values="filterValues"
        :initial-fetch-completed="initialFetchCompleted"
        :notes="notes"
        :pagination="pagination"
        :readonly="true"
        @apply-filters="applyFilters"
        @clear-filters="clearFilters"
        @fetch-next-page="fetchNextPage"
        @refresh-items="refreshItems"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';
    import { useGlobalStore } from '@/store/store';

    import NotesBox from '@/components/container/boxes/NotesBox';
    import NotesBoxMixin from '@/mixins/NotesBoxMixin';

    import CommentType from '@/entities/CommentType';
    import Division from '@/entities/Division';
    import LeadType from '@/entities/LeadType';

    export default {
        components: {
            NotesBox,
        },

        mixins: [NotesBoxMixin],

        data: () => ({
            filtersStorageKey: 'customer.notes.filters',
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextCustomer: 'customer',
            }),

            filters() {
                return {
                    division: {
                        label: 'Division',
                        multiple: true,
                        options: Division
                            .selectOptions(this.contextCustomer.account, 'value', 'text')
                            .filter(option => !option.invalid),
                        type: 'select',
                        value: this.filterValues.division,
                    },
                    leadType: {
                        label: this.$t('clientCard.boxes.notes.filters.leadType'),
                        multiple: true,
                        options: LeadType
                            .selectOptions(this.contextCustomer.account, this.authUser, [], 'value', 'text')
                            .filter(option => !option.invalid),
                        type: 'select',
                        value: this.filterValues.leadType,
                    },
                    noteType: {
                        label: this.$t('clientCard.boxes.notes.filters.noteType.title'),
                        multiple: true,
                        options: CommentType.selectOptions(this.contextCustomer.account, 'value', 'text'),
                        type: 'select',
                        value: this.filterValues.noteType,
                    },
                    user: {
                        label: 'User',
                        multiple: true,
                        options: this.activeUsers.map(user => ({
                            value: user.id,
                            text: user.fullName,
                        })),
                        type: 'select',
                        value: this.filterValues.user,
                    },
                };
            },
        },

        methods: {
            async fetchPage(page, replaceItems = false) {
                if (!this.contextCustomer.id) {
                    return;
                }

                this.$wait.start('fetching.notes');

                const response = await this.$axios.get(`v1/customers/${this.contextCustomer.id}/comments`, {
                    params: {
                        filter: this.filterValues,
                        page,
                        per_page: this.pagination.perPage,
                    },
                });

                const fetchedNotes = Object.values(response.data.data);

                this.mergeFetchedNotes(fetchedNotes, replaceItems);

                this.pagination.totalItemsCount = response.data.meta.total;

                this.$wait.end('fetching.notes');

                this.initialFetchCompleted = true;
            },
        },
    };
</script>
