<template>
    <div class="flex flex-grow items-center gap-3">
        <invisible-input
            :error="hasNumber && !phone.isValid"
            :mask="phoneMask"
            :readonly="true"
            :value="phoneValue"
        >
            <template #after>
                <div
                    class="absolute inset-0 flex items-center justify-center z-10"
                    :class="{ 'rounded-md bg-gray-900/50': revealing }"
                    @click="revealPhone"
                    v-if="masked"
                >
                    <div class="rounded-full bg-gray-800 px-2 text-lg text-white font-bold" v-if="revealing">
                        {{ revealTimer }}
                    </div>
                </div>
            </template>
        </invisible-input>

        <invisible-input
            class="max-w-[40%]"
            before="#"
            mask="0000000000"
            :animated="false"
            :readonly="true"
            :value="phone.extension"
            v-if="showExtension"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import CustomerPhone from '@/entities/CustomerPhone';
    import LeadPhoneType from '@/entities/LeadPhoneType';

    export default {
        components: { InvisibleInput },

        props: {
            phone: {
                type: CustomerPhone,
                required: true,
                validator: phone => {
                    return LeadPhoneType.exists(phone.type);
                },
            },
        },

        data: () => {
            return {
                revealed: false,
                revealing: false,
                revealTimer: 3,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            extensionValue() {
                if (!this.showExtension) {
                    return '';
                }

                return this.formatExtension(this.phone.extension);
            },

            hasNumber() {
                return !!this.phone.number;
            },

            masked() {
                if (!this.authUser.block_lead_info) {
                    return false;
                }

                if (!this.phone.isValid) {
                    return false;
                }

                return !this.revealed;
            },

            phoneMask() {
                return this.masked ? null : 'phone';
            },

            phoneValue() {
                const value = this.phone.format() || this.phone.number || null;

                if (this.masked) {
                    return this.maskPhone(value);
                }

                return value;
            },

            showExtension() {
                return this.phone.isWorkType && this.phone.isValid;
            },
        },

        methods: {
            revealPhone() {
                this.revealing = true;

                const interval = setInterval(() => {
                    if (--this.revealTimer === 0) {
                        this.revealed = true;

                        clearInterval(interval);
                    }
                }, 1000);
            },
        },
    };
</script>
